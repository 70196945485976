import { Button, Form, Input, notification } from 'antd';
import React, { useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import { RequestUtils } from 'utils/Request';
import { UserContext } from "content/ContextProject";
import { useNavigate } from 'react-router-dom';
import { setTokenAxios } from 'const/function';

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

const Login: React.FC = () => {
  const { dispatch } = useContext(UserContext);
  const navigate = useNavigate();

  const onFinish = async (value: any) => {
    // GATE_EVN
    await RequestUtils.PostWeb('/auth/sign-in', value).then(data => {

      if (data.errorCode === 200) {
        setTokenAxios(data.data.jwtToken);
        dispatch({ type: 'POST_USER_DATA_SUCCESS', payload: data?.data.user });
        Cookies.set('jwtToken', JSON.stringify(data.data.jwtToken || ''), { expires: 0.5, path: '/', domain: process.env.DOMAIN_HOST });
        navigate("/list-content", { replace: true });
      } else {
        notification.error({
          message: 'Tài khoản hoặc mật khẩu không chính xác!',
        });
      }
    })
  }

  useEffect(() => {
    /* Nếu có token thì return về trang quản trị*/
    const token = Cookies.get('jwtToken');
    if (token) {
      navigate("/list-content", { replace: true });
    }
  }, [navigate]);

  return (
    <div>
      <div style={{ width: '30%', background: '#fff', padding: 20, margin: 'auto', borderRadius: 10 }}>
        <div style={{display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 30}}>
          <img className="image" src={"/svg/Logo_onez.svg"} alt="Logo" style={{marginLeft: 15}}/>
        </div>
        <h1 style={{ textAlign: 'center', fontWeight: 500 }}>ĐĂNG NHẬP HỆ THỐNG</h1>
        <Form
          name="basic"
          layout='vertical'
          labelCol={{ span: 16 }}
          wrapperCol={{ span: 24 }}
          style={{ width: '100%', margin: 'auto' }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            label="Tên đăng nhập"
            name="username"
            rules={[{ required: true, message: 'Vui lòng nhập họ và tên!' }]}
          >
            <Input style={{ width: '100%', padding: 10, borderRadius: 50 }} placeholder='Tên đăng nhập' />
          </Form.Item>
          <Form.Item<FieldType>
            label="Mật khẩu"
            name="password"
            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
          >
            <Input.Password style={{ width: '100%', padding: 10, borderRadius: 50 }} placeholder='Mật khẩu' />
          </Form.Item>
          <Button type="primary" htmlType="submit" 
            style={{ background: '#f37021', width: '100%', height: 40, marginTop: 20, marginBottom: 20, borderRadius: 50 }}>
            Đăng nhập
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default Login