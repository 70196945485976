import { Modal } from "antd";
import styled from "styled-components";

export const ModalCustomerStyle = styled(Modal)`
    .ant-modal-content {
        width: 693px;
        z-index: 9999 !important;
    }

    .ant-modal-content {
        padding: 0px 0px !important;
    }
    .ant-modal-header {
        background: #2CBDB3 !important;
        border-radius: 10px 10px 0 0 !important;
    }
    .ant-modal-body {
        padding: 10px 33px 43px 25px !important;
    }
    .ant-modal-title {
        font-weight: 700 !important;
        font-size: 10px 27px !important;
        line-height: 24px !important;
        color: #fff !important;
        padding: 24px;
    }

    @media (max-width: 1024px) {
        .ant-modal-content {
            max-width: 100%;
            z-index: 9999 !important;
        }
    }
`

export const ModalJobStyle = styled(Modal)`
    margin-left: 25%;
    .ant-modal-content {
        width: 1108px;
        z-index: 9999 !important;
    }

    .ant-modal-content {
        padding: 0px 0px !important;
    }
    .ant-modal-header {
        background: #2CBDB3 !important;
        border-radius: 10px 10px 0 0 !important;
        margin-bottom: 0px !important;
    }

    .ant-modal-title {
        font-weight: 700 !important;
        font-size: 10px 27px !important;
        line-height: 24px !important;
        color: #fff !important;
        padding: 24px;
    }
    @media (max-width: 1024px) {
        .ant-modal-content {
            max-width: 100%;
            z-index: 9999 !important;
        }
    }
`

export const WrapPerStyle = styled.div`
    .wrapStyle {
        padding-bottom: 10;
        display: flex;
        gap: 10px;
        padding-top: 10px;
        border-bottom: 1px dashed #BFCBD9,
    }
    .step {
        width: 80%; 
        margin: auto; 
        margin-bottom: 40px;
    }
    .upload {
        border: 1px dashed #BFCBD9;
        width: 100%;
        height: 174px;
        cursor: pointer;
        display: flex; 
        justify-content: center; 
        align-items: center;
        margin-top: 25px;
        background: #fff;
    }

`

export const ModalAddEmailStyle = styled(Modal)`
    .ant-modal-content {
        width: 376px;
        margin:auto;
        margin-top: 30% !important;
        z-index: 9999 !important;
    }
`
 
export const BtnButton = styled.div`
    display: flex;
    gap: 10px;
    .btn-left {
        background: #F0F2F5;
        border-radius: 4px;
        border: none;
        height: 29px;
        font-weight: 500;
        &:hover {
            color: #000;
        }
    }
    .btn-right {
        background: #2CBDB3;
        border-radius: 4px;
        border: none;
        color: #fff;
        width: 82px;
        height: 29px;
        font-weight: 500;
        &:hover {
            color: #fff;
        }
    }
`

export const FormWrap = styled.div`
    height: 593px;
    border: 1px solid #BFCBD9;
    margin-top: 20px;
    padding: 30px;

`