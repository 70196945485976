import React, { useContext } from "react";
import { HeaderStyle } from "./style";
import { BellOutlined, MenuOutlined } from "@ant-design/icons";
import { Avatar, Badge, Typography } from "antd";
import { UserContext } from "content/ContextProject";
import RegexName from "components/RegexName";


type TogleProps = {
  collapsed: boolean,
  toggleCollapsed: (f: boolean) => void
}

const Header: React.FC<TogleProps> = ({collapsed, toggleCollapsed }) => {

  const { state } = useContext(UserContext);

  return (
    <div>
      <header>
        <HeaderStyle>
          <div className="header_nav">
           <div style={{width: '85%', display: 'flex', alignItems: 'center' , marginLeft: collapsed ? '0%' : '12%'}}>
              <MenuOutlined onClick={() => toggleCollapsed(!collapsed)} style={{marginLeft: 100}}/>
            </div> 
            <div style={{display: 'flex',alignItems: 'center', gap: 25, width: '15%'}}>
              <Badge count={1}>
                <BellOutlined style={{fontSize: 22}}/>
              </Badge>
              <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                <Avatar style={{ backgroundColor: '#f56a00' }}>{RegexName(state.data?.sub)}</Avatar>
                <div>
                  <Typography.Text>{state.data?.sub}</Typography.Text><br/>
                </div>
              </div>
            </div>
          </div>
        </HeaderStyle>
      </header>
    </div>
  );
};

export default Header;
