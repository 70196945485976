import React from "react";

type TextOwnProps<E extends React.ElementType> = {
  onClick?: (v: never) => void;
  variant?: "primary" | "secondary" | "tertiary";
  typeElement?: E;
  style?: React.CSSProperties;
  className?: string;
  children: React.ReactNode;
};

type TextProps<E extends React.ElementType> = TextOwnProps<E> & Omit<React.ComponentProps<E>, keyof TextOwnProps<E>>

export const TextStyle = <E extends React.ElementType = "div">({
  style,
  onClick,
  children,
  typeElement,
  className,
}: TextProps<E>) => {
  const Element = typeElement || "div";

  return (
    <Element
      style={style}
      className={className}
      onClick={onClick}
    >
      {children}
    </Element>
  );
};
