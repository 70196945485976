import React from 'react'

import { ModalAddEmailStyle } from './style';

type ModalProps  = {
    isAddUser: boolean,
    setIsAddUser: (f: boolean) => void,
    children: React.ReactNode
}

const ModalConfirm: React.FC<ModalProps> = ({isAddUser, setIsAddUser, children}) => {
  return (
    <ModalAddEmailStyle open={isAddUser} closable={false} onCancel={() => setIsAddUser(false)} footer={false}>
        {children}
    </ModalAddEmailStyle>
  )
}

export default ModalConfirm
