import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./Store";
import { fetchListProject } from "../components/services/ListProject";

interface ListStatusProps {
    workDay: number;
    workCompleted: number;
    workProgress: number;
    workOverdue: number
}

interface State {
    ListStatus: ListStatusProps | null;
    status: | 'loading' | 'succeeded' | 'failed';
    error: string | null | undefined
}

const initialState: State = {
    ListStatus: null,
    status: 'loading',
    error: null,
}; 
   
  const ProjectSlice = createSlice({
    name: 'api',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchListProject.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchListProject.fulfilled, (state, action) => {          
          state.status = 'succeeded';
          state.ListStatus = action.payload;
        })
        .addCase(fetchListProject.rejected, (state, action) => {            
          state.status = 'failed';
          state.error = action.error.message;
        });
    },
  });
  
export const selectorListProject = (state: RootState) => state;
export default ProjectSlice.reducer;