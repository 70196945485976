import styled from "styled-components";

export const HeaderStyle = styled.div`
  font-size: 1.5em;
  width: 960px;
  .header_nav {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    height: 70px;
    z-index: 999;
  }
  .image {
    left: 1.25%;
    right: 86.3%;
    top: 0%;
    bottom: 0%;
  }
`;
