import { Suspense } from "react";
import "./App.css";
import Loading from "./components/routers/common/Loading";
import MainLayout from "./components/layout/MainLayout";
import { UserProvider } from "content/ContextProject";
import { useLocation } from "react-router-dom";
import { PATH_NAME } from "const/const";

function App() {
  const location = useLocation();

  const loginStyle = {
    width: '100%',
    height: '100vh',
    backgroundImage: 'url(/img/sean-pollock.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  const containerStyle = location.pathname === PATH_NAME.login ? loginStyle : {};

  return (
    <div style={{ height: '100vh' }}>
      <div style={containerStyle}>
        <UserProvider>
          <Suspense fallback={<Loading />}>
            <MainLayout />
          </Suspense>
        </UserProvider>
      </div>
    </div>
  );
}

export default App;
