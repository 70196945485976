import { TYPE_STATUS } from "./const";

export const GATE_EVN = {
    Loc: process.env.REACT_APP_HOST,
    ImageUrl: process.env.REACT_APP_HOST + '/uploads/media'
}

export const GATEWAY = GATE_EVN['Loc'];

export const API = {
    JOB_OVERVIEW: '/workname/count-work',
    LIST_PROJECT: '/project/search',
    ADD_PROJECT: '/project/created',
    ADD_WORK: '/workname/created',
    ADD_WORK_DETAIL: '/workdetail/created',
    LIST_OVERVIEW_PROJECT: '/project/find',
    HISTORY_PROJET: '/history/search/',
    LIST_WORK_DAY: '/workdetail/list-work-day',
    LIST_PROGRESS: '/workdetail/list-work-progess',
    LIST_COMPLETED: '/workdetail/list-work-completed',
    LIST_OVERDUE: '/workdetail/list-work-overdue',
    LIST_CHART_GANTT: '/project/gantt-chart',
    WORK_NAME_SEARCH: '/workname/search',
    FIND_WORKNAME: '/workname/find',
    LIST_WORK_DETAIL_SEARCH: '/workdetail/search',
    TAG_LIST: '/tag/list',
    USER_LIST: '/user/list',
    UPDATE_WORKNAME: '/workname/update/',
    UPDATE_PROJECT: '/project/update/',
    ROOM_USER: '/roomuser/search',
    UPDATE_HEAD_WORK: '/workdetail/update/',
    UPDATE_HEAD_WORK_DETAIL: '/workdetail/find',

    CREATE: '/data/create',
    GET_DATA: '/data/get-page',
    FIND_DATA: '/data/find-id',
    STOCK_CONTENT: '/stock-content/save',
    STOCK_CONTENT_FETCH: '/stock-content/fetch-data',
    MEDIA_UPLOAD_FILE: '/media/upload-file',
}


export const STATUS_WORK = (status: number) => {
    switch(status) {
        case TYPE_STATUS.ONE:
          return 'Đang triển khai';
        case TYPE_STATUS.TWO:
            return 'Hoàn thành';  
        case TYPE_STATUS.THREE: 
            return 'Quá hạn';
    }
}

export const PluginToolbar = [
    'heading', '|',
    'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|',
    'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', '|',
    'uploadImage', 'mediaEmbed', '|',
    'undo', 'redo'
]

export const REGEX = {
    phone: /^(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/,
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
}

export const ApiFileEditorFile = 'https://api.ckbox.io/assets?workspaceId=aebd26c0e58652bbd8e1';
