import React from 'react'
import { Routes, Route } from "react-router-dom";
import { ProjectRouter } from './ListConfig';

const LayoutRouter: React.FC = () => {

  return (
    <Routes>
      { ProjectRouter.map((route, index) => {
        const Page = route.component;
        return (
          <Route key={index} path={route.path} element={<Page />} />
        )
      })}
    </Routes>
  )
}

export default LayoutRouter
