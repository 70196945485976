import React, { Suspense, useEffect, useState } from "react";
import { Layout } from "antd";

import LayoutDashboad from "./LayoutDashboad";
import Header from "components/header/Header";
import RouterConfig from "components/routers/common/RouterConfig";
import Loading from "components/routers/common/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH_NAME } from "const/const";
import Cookies from "js-cookie";

const { Content, Sider } = Layout;
const MainLayout: React.FC = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState<boolean>(false);

  useEffect(() => {
    const token = Cookies.get('jwtToken');
    if (!token && !location.pathname) {
      return navigate("/login", { replace: true });
    } else {
      if (location.pathname === "/") {
        navigate("/list-content", { replace: true });
      }
    }
  }, [navigate, location]);

  return (
    <div>
      {location.pathname === PATH_NAME.login ? (
        <div>
          <Suspense fallback={<Loading />}>
            <div style={{ paddingTop: 200 }}></div>
            <RouterConfig />
          </Suspense>
        </div>
      ) : (
        <Layout>
          <Sider
            collapsed={collapsed}
            style={{ minHeight: '100vh', paddingTop: 100 }}
            width={280}
          >
            <LayoutDashboad />
          </Sider>
          <Layout>
            <Header collapsed={collapsed} toggleCollapsed={setCollapsed} />
            <Layout className="your-element">
              <Content>
                <Suspense fallback={<Loading />}>
                  <div style={{ paddingTop: 80 }}></div>
                  <RouterConfig />
                </Suspense>
              </Content>
            </Layout>
          </Layout>
        </Layout>
      )}
      {/* <NotifyMessageLazy /> */}
    </div>
  );
};

export default MainLayout;
