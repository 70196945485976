// import ProjectListConfig from '../listProject/ProjectListConfig';

import LayoutRouter from "components/routers/listProject/LayoutRouter";

const routeConfigs = [
  <LayoutRouter/>
];

const RouterConfig = () => {
  return (
    <div>
      {routeConfigs.map((item, i) => (
        <div key={i}>{item}</div>
      ))}
    </div>
  )
}

export default RouterConfig
