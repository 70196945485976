import axios from "axios";

export const money = (x: number | string) => !x ? 0 : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

export const formatTime = (d: number | any) => {
  if (d instanceof Date) {
    return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
  }
  return 'N/A';
}

export const setTokenAxios = (tk: any) => axios.defaults.headers.common['Authorization'] = 'Bearer ' + tk;


// hàm convert thời gian
export function NewfDate(date: Date) {
  const time = new Date(date);
  return `${time.getDate()}-${time.getMonth()}-${time.getFullYear()}`
}