import styled from "styled-components";

export const MenuStyle = styled.div`
  .btn_chat {
    width: 235px;
    height: 41px;
    left: 30px;
    top: 20px;
    margin-bottom: 40px;
    background: #00C6B5;
    border-radius: 38px;
    border: none;
    &:hover {
      color: #000;
    }
  }
  .btn_phone {
    width: 235px;
    height: 41px;
    left: 30px;
    background: #FFD243;
    border-radius: 38px;
    border: none;
    &:hover {
      color: #000;
    }
  }
`;