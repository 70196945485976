import axios from "axios";
import { GATEWAY } from "../const/config";

type ValueRequestProps = {
    input: string[] | string | null,
    service: string | null ,
    method?: 'GET' | 'POST',
    defaultValue?: Object
}

type QueryData = Omit<ValueRequestProps, 'api' | 'service' | 'method'> | any;

const encodeQueryData = (input: QueryData) => {
    if(!input) {
        return '';
    }
    const ret: Array<[]> = [];
    for(let d in input) {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(input[d]) as never)
    }
    return '?' + ret.join('&');
}

const httpRequest = (service: string, input: string | string[] | any, method: String = 'GET')  => {
    const _uri = GATEWAY + service;
    let getOrPost;
    if(method === 'GET') {
        getOrPost = axios.get(_uri + encodeQueryData(input));
    } else if(method === 'PUT') {
        getOrPost = axios.put(_uri, input)  
    }
    else {
        getOrPost = axios.post(_uri, input);
    }
    return getOrPost.then((responseJson) => {
       return responseJson;
    }).catch(error => {
        throw error
    })
}

export const RequestUtils = {
    async GetWeb(service = '', input = null, defaultValue = {}) {
        const ret = await httpRequest(service, input, 'GET');        
        return ret.status === 200 ? ret.data : defaultValue;
    },
    async PostWeb(service = '', input = '', defaultValue = {}) {            
        const ret = await httpRequest(service, input, 'POST' );
        return ret?.status === 200 ? ret.data : defaultValue;
    },
    async PushWeb(service = '', input = '', defaultValue = {}) {            
        const ret = await httpRequest(service, input, 'PUT' );
        return ret?.status === 200 ? ret.data : defaultValue;
    }
}