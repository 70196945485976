import React, { createContext, useEffect, useReducer } from 'react';
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';

type UserData = {
  username: string;
  password: string;
};

type UserState = {
  data?: any | null;
  isLoading: boolean;
  error?: string | null;
};

type ChildrenProps = {
  children: React.ReactNode;
}

type UserAction = { type: 'POST_USER_DATA_SUCCESS'; payload: UserData }
  | { type: 'POST_USER_DATA_FAILURE'; payload: string }
  | { type: 'POST_USER_DATA_REQUEST' };

type UserDispatch = (action: UserAction) => void;

const UserContext = createContext<{ state: UserState; dispatch: UserDispatch }>({
  state: { isLoading: false },
  dispatch: () => null,
});

const userReducer = (state: UserState, action: UserAction) => {
  switch (action.type) {
    case 'POST_USER_DATA_SUCCESS': {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    }
    case 'POST_USER_DATA_FAILURE': {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    }
    case 'POST_USER_DATA_REQUEST': {
      return {
        ...state,
        isLoading: true,
      };
    }
    default: {
      return state;
    }
  }
};

const UserProvider: React.FC<ChildrenProps> = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, { isLoading: false });
  const navigate = useNavigate();

  useEffect(() => {
    if (!Cookies.get('jwtToken')) {
      navigate('/login');
    } else {
      const token = JSON.parse(Cookies.get('jwtToken') as any || null);
      const decoded = jwtDecode(token) as never;
      if (decoded !== undefined) {
        dispatch({ type: 'POST_USER_DATA_SUCCESS', payload: decoded });
      }
    }
  }, [navigate]);

  useEffect(() => {
    /* check token hết hạn thì chuyển vê trang /login */
    (() => {
      if (!Cookies.get('jwtToken')) {
        return navigate("/login", { replace: false });
      } else {
        const token = JSON.parse(Cookies.get('jwtToken') || '');
        const decoded = jwtDecode(token) as any;
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          Cookies.remove('token', { path: '/login', domain: process.env.DOMAIN_HOST })
          navigate('/login');
          return;
        }
      }
    })()
  }, [navigate])

  return <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>;
};

export { UserProvider, UserContext };