import { TextStyle } from './Polymorphism/TextStyle';

const RegexName = (name: string) => {

  const repex = name?.split(" ");  
  const firtIndex = repex?.slice(0)[0] || '';
  const lastIndex = repex?.slice(1)[0] || '';

  return (
    <div>
        <TextStyle typeElement='span'>{name?.match(/^\d{10}$/g) ?  'PV' : (firtIndex?.charAt(0) + lastIndex?.charAt(0)).toUpperCase()}</TextStyle>
    </div>
  )
}

export default RegexName
