import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API, GATEWAY } from 'const/config';

export const ListDetailOverViewApi = createApi({
    reducerPath: 'ListDetalOverView',
    tagTypes: ['ViewDetail'],
    baseQuery: fetchBaseQuery({ baseUrl: `${GATEWAY}${API.LIST_OVERVIEW_PROJECT}` }),
    endpoints: (builder) => ({
      getListDetailOverVIew: builder.query({
        query: (code) => `?projectCode=${code}`,
      }),
    }),
})

export const ListHistoryProjectApi = createApi({
  reducerPath: 'ListHistory',
  tagTypes: ['HistorProject'],
  baseQuery: fetchBaseQuery({ baseUrl: `${GATEWAY}${API.HISTORY_PROJET}`}),
  endpoints: (builder) => ({
    getListHistory: builder.query({
      query: (code) => `${code}`,
    }),
  }),
})


export const { useGetListDetailOverVIewQuery } = ListDetailOverViewApi;
export const { useGetListHistoryQuery } = ListHistoryProjectApi;