import { configureStore } from "@reduxjs/toolkit";
// import thunkMiddleware from 'redux-thunk';
import ProjectSlice  from "./Reducer";
import { ListDetailOverViewApi, ListHistoryProjectApi } from "./ListApi/Api_Redux_Toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

export const store = configureStore({
    reducer: {
      ProjectSlice: ProjectSlice,
      [ListDetailOverViewApi.reducerPath]: ListDetailOverViewApi.reducer,
      [ListHistoryProjectApi.reducerPath]: ListHistoryProjectApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        ListDetailOverViewApi.middleware,
        ListHistoryProjectApi.middleware,
      ]),
});

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch