import React, { FunctionComponent } from "react";

import Login from "../auth/Login";

const LazyList = React.lazy(() => import('./List'));
const LazyDetailContent = React.lazy(() => import('./DetailContent'));
const LazyAddContent = React.lazy(() => import('./AddContent'));
const LazyListTuyenDung = React.lazy(() => import('./ListTuyenDung'));
const LazyAddTuyenDung = React.lazy(() => import('./AddTuyenDung'));
const LazyDetailTuyenDung = React.lazy(() => import('./DetailTuyenDung'));


type ComponentType<P = {}> = FunctionComponent<P>;

type RouterProps = {
  path: string,
  component: ComponentType
}

const ProjectRouter: RouterProps[] = [
  { path: '/list-content', component: LazyList },
  { path: '/list-tuyen-dung', component: LazyListTuyenDung },
  { path: '/add-content', component: LazyAddContent },
  { path: '/detail-content/:id', component: LazyDetailContent },
  { path: '/add-tuyen-dung', component: LazyAddTuyenDung },
  { path: '/detail-tuyen-dung', component: LazyDetailTuyenDung },
  { path: '/login', component: Login},
]

const privateRouter: Array<[]> = [];

export { ProjectRouter, privateRouter};
