export const INAPP_NOTIFICATION_EMITTER = 'inapp-noty';

export enum TYPE_STATUS {
  lead = 'lead',
  ONE = 1,
  TWO = 2,
  THREE = 3,
}

export enum PATH_NAME {
  login = '/login',
}