import { Button, Menu, Typography } from "antd";
import React, { useState } from "react";
import {
  PieChartOutlined,
  LoginOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";

import { MenuStyle } from "./style";
import { TextStyle } from "components/Polymorphism/TextStyle";
import ModalConfirm from "components/Modal/ModalConfirm";
import { BtnButton } from "components/Modal/style";
import Cookies from "js-cookie";

const LayoutDashboad: React.FC<any> = () => {

  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const onHandleLogout = () => {
    Cookies.remove('jwtToken');
    navigate('/login');
  }

  return (
    <MenuStyle>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 30 }}>
        <img src={"/svg/Logo_onez.svg"} alt="Logo" style={{ marginLeft: 15 }} />
      </div>
      <Menu
        mode="inline"
        theme="dark"
        defaultSelectedKeys={['1']}
      >
        <Menu.Item key="1" icon={<CalendarOutlined />}>
          <Link to="/list-content"> Nội dung</Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<PieChartOutlined />}>
          <Link to="/list-tuyen-dung"> Lead</Link>
        </Menu.Item>
        <Menu.Item key="4" icon={<LoginOutlined />} onClick={(() => setOpen(true))}>
          <TextStyle typeElement={'p'}> Đăng xuất</TextStyle>
        </Menu.Item>
        <div style={{ width: 280, borderBottom: '1px solid #9999', paddingTop: 50, marginBottom: 50 }}></div>
      </Menu>
      <ModalConfirm isAddUser={open} setIsAddUser={setOpen}>
        <Typography.Title level={4} style={{ margin: 0, fontWeight: 500 }}>Bạn có muốn đăng xuất ?</Typography.Title>
        <div style={{ border: '1px solid #E1E1E1', marginTop: 10 }}></div>
        <BtnButton style={{ justifyContent: 'flex-end', gap: 10, marginTop: 20 }}>
          <Button className="btn-left" style={{ borderRadius: 50, background: '#E83232', color: '#FFF', fontWeight: 500 }}
            onClick={onHandleLogout}
          >
            Đăng xuất
          </Button>
          <Button className="btn-right" onClick={() => setOpen(false)} style={{ borderRadius: 50, background: '#D9D9D9', fontWeight: 500 }}>
            HỦY
          </Button>
        </BtnButton>
      </ModalConfirm>
    </MenuStyle>
  );
};

export default LayoutDashboad;
